<template>
  <div class="card computed-min-height">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          title="Add New Budget"
          @onClickAddNewButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-6">
            <label class="align-middle">Budget Name</label>
            <input
                placeholder="Budget name"
                v-model="formData.budget_name"
                class="form-control"
            />
          </div>
          <div class="col-sm-3">
            <label class="align-middle">Budget Code</label>
            <input type="text" class="form-control" v-model="formData.budget_code">
          </div>
          <div class="col-sm-3">
            <label class="align-middle">Create Date</label>
            <input type="date" class="form-control" v-model="formData.create_date">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-6">
            <label class="align-middle">Business Line</label>
            <v-select
                placeholder="Select Business Line"
                v-model="formData.business_line"
                :options="businessLines"
                label="line"
                :reduce="line => line.id"
            />
          </div>
          <div class="col-sm-3">
            <label class="align-middle">Start Date</label>
            <input type="date" class="form-control" v-model="formData.start_date">
          </div>
          <div class="col-sm-3">
            <label class="align-middle">End Date</label>
            <input type="date" class="form-control" v-model="formData.end_date">
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-sm-6">
            <label class="align-middle">Project</label>
            <v-select
                placeholder="Select Warehouse"
                v-model="formData.project_id"
                :options="projects"
                label="project"
                :reduce="project => project.id"
            />
          </div>
          <div class="col-sm-3">
            <label class="align-middle">Interval</label>
            <v-select
                placeholder="Select Interval"
                v-model="formData.interval"
                :options="intervals"
                label="interval"
                :reduce="interval => interval.id"
            />
          </div>
          <div class="col-sm-3">
            <label class="align-middle">Pre-fill Date</label>
            <v-select
                placeholder="Select Pre-fill"
                v-model="formData.pre_fill_date"
                :options="prefillDates"
                label="prefill"
                :reduce="prefill => prefill.id"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white p-1">

      <div class="px-2 mt-2">
        <BudgetSetup
            class="mb-2"
            :account-heads="accountHeads"
            :item="formData.budget_setup"
        />
      </div>
    </div>

    <div class="bg-blue-light p-1">
      <div class="mt-2">
        <div class="d-flex flex-wrap gap-1 gy-2 justify-content-center">
          <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
                  class="btn btn-primary">
            Save
          </button>
          <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
                  class="btn btn-primary">Save & New
          </button>
          <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
        </div>
      </div>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {inject, ref} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import BudgetSetup from "@/components/molecule/procurement/budget/BudgetSetup";

const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess')

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let businessLines = ref([])
let projects = ref([])
let intervals = ref([])
let prefillDates = ref([])
let accountHeads = ref([])
let formData = ref({
  budget_name: null,
  budget_code: null,
  create_date: null,
  business_line: null,
  start_date: null,
  end_date: null,
  project_id: null,
  interval: null,
  pre_fill_date: null,
  budget_setup: {
    budget_head_id: null,
    account_head_id: null,
    monthly_qty: null,
  },
})

function navigateToListPage() {
  router.push({name: `budget-list`, params: route.params, query: route.query});
}

function handleSubmit() {

}
</script>