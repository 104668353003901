<template>
  <div style="border-radius: 8px" class="row journal-line">
    <div class="col-12 d-flex product-details-border position-relative pe-0">
      <div class="row w-100 pe-lg-0 me-1 py-2">
        <div class="col-lg-12">
          <table class="table">
            <thead>
            <tr>
              <th>Budget Head</th>
              <th>Account Head</th>
              <th>July</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <input
                    v-model="item.budget_head_id"
                    class="form-control"
                    placeholder="Budget Head"
                />
              </td>
              <td>
                <v-select
                    placeholder="Select Account Head"
                    v-model="item.account_head_id"
                    :options="accountHeads"
                    label="account"
                    :reduce="account => account.id"
                />
              </td>
              <td>
                <input
                    v-model="item.monthly_qty"
                    class="form-control"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BudgetSetup',
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    },
    accountHeads: {
      type: Array
    }
  }
}
</script>